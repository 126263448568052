<template>
<div class="footer">
  <div class="firstLine">
    <div class="footerTop">
      <div class="left">
        <div class="col">
          <h1>Explore</h1>
          <p  @click="goRouter('/Features')">Features</p>
          <p  @click="goRouter('/Pricing')">Pricing</p>
          <p @click="goRouter('/AboutUs')">About Us</p>
          <p  @click="goRouter('/Arcanite')">Arcanite</p>
          <p  @click="goRouter('/ArcaniteConnect')">Arcanite Connect</p>
          <p  @click="goRouter('/ArcanitePartners')">Arcanite Partners</p>
          <p  @click="goRouter('/DragonVR')">DragonVR</p>
          <!-- <p  @click="goRouter('/Equimate')">Equimate</p> -->
          <p  @click="goRouter('/Marketplace')">Marketplace</p>
        </div>
        <div class="col">
          <h1>Resources</h1>
          <p @click="goRouter('/Blogs')">Blogs</p>
          <a href="https://apps.apple.com/au/app/arc-agent/id1510107896" target="_blank">
            <p>Agent App (iPhone)</p>
          </a>
          <a href="https://play.google.com/store/apps/details?id=au.com.arcnet.agent " target="_blank">
          <p>Agent App (Android)</p>
          </a>
		  <a href="https://apps.apple.com/au/app/arcanite-connect/id1598271065" target="_blank">
		  <p>Admin App (iPhone)</p>
		  </a>
		  <a href="https://play.google.com/store/apps/details?id=au.com.arcnet.admin" target="_blank">
		  <p>Admin App (Android)</p>
		  </a>
          <a href="https://agent.arcnet.com.au/" target="_blank">
          <p>Agent Web Login</p>
          </a>
          <a href="https://agency.arcnet.com.au/" target="_blank">
          <p>Admin Web Login</p>
          </a>
          <a href="https://partner.arcnet.com.au/" target="_blank"> 
          <p>Partner Portal Login</p>
          </a>
        </div>
        <div class="col">
          <h1>Contact</h1>
          <!-- <a href="" -->
          <a href="mailto:support@arcnet.com.au">
          <p>support@arcnet.com.au</p>
          </a>
          <p>100 Barangaroo Avenue, <br/>Barangaroo NSW 2000 <br/>Australia</p>
        </div>
      </div>
      <div class="right">
        <h1>
          Ready to  get started?
        </h1>
        <div v-if="!isID" class="btn">
          <a onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');return false;">
          <button class="btnA" :class="[isID?'IDbtnA':'']"><span class="text">{{ $t("pricing.book_a_demo") }}&nbsp;&nbsp;&nbsp;</span><img src="./../../assets/imgs/pc/footer/arrorRight.png" class="arrow"/></button>
          <!-- <botton class="btnB"><span class="text">Sign Up Now</span></botton> -->
          </a>
        </div>
        <div v-else class="btn">
          <a onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');return false;">
          <button class="btnA" :class="[isID?'IDbtnA':'']"><span class="text">{{ $t("pricing.book_a_demo") }}&nbsp;&nbsp;&nbsp;</span><img src="./../../assets/imgs/pc/footer/arrorRight.png" class="arrow"/></button>
          <!-- <botton class="btnB"><span class="text">Sign Up Now</span></botton> -->
          </a>
        </div>
        <h1>Follow Us</h1>
        <div class="icons">
          <a href="https://www.linkedin.com/company/arc-au/" target="_blank"><img src="./../../assets/imgs/pc/footer/linkin.png" /></a>
          <a href="https://www.instagram.com/arcanite.au/" target="_blank"><img src="./../../assets/imgs/pc/footer/ins.png" /></a>
          <a href="https://www.facebook.com/people/Arcanite/100076342056431/" target="_blank"><img src="./../../assets/imgs/pc/footer/facebook.png" /></a>
          <a href="https://twitter.com/Arcanite_au" target="_blank"><img src="./../../assets/imgs/pc/footer/twitter.png" /></a>
        </div>
        <h1>Country</h1>
        <div class="icons">
          <div class="ImgSpace" v-if="value=='au'"><img class="countryImgOutSide" style="margin:0;" src="./../../assets/imgs/pc/footer/australia.png"/></div>
          <div class="ImgSpace" v-if="value=='id'"><img class="countryImgOutSide" style="margin:0;" src="./../../assets/imgs/pc/footer/indonesia.png"/></div>
          <el-select class="selectStyle" v-model="value" @change="changeLanguage">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              >
              <div class="countryOptions">
                <div v-if="item.value=='au'"><img class="countryImg" src="./../../assets/imgs/pc/footer/australia.png"/></div>
                <div v-if="item.value=='id'"><img class="countryImg" src="./../../assets/imgs/pc/footer/indonesia.png"/></div>
                <div class="countryOptionsText">{{item.label}}</div>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>   
  </div>
  <div class="secondLine">
    <div class="footBottom">
      <div class="middle">
        <img src="./../../assets/imgs/pc/footer/icon1.png" class="icon1"/>
        <p>© 2023 Arcanite</p>
        <p @click="goRouter('/PrivacyPolicy')">Privacy Policy</p>
        <p @click="goRouter('/TermsAndConditions')">Terms & Conditions</p>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { replaceUrl } from "@/assets/js/utility.js";

export default {
  data(){
    return {
      isID:false,
      userId: '',
      name: "",
      email: "",
      value: 'au',
      options: [{
        value: 'au',
        label: 'AU'
      }, {
        value: 'id',
        label: 'ID',
        // disabled: true
      }],
    }
  },
  created(){
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    // wwjw
    // console.log("window.location",window.location.host)
    this.value = localStorage.getItem('country') || 'au'
    // this.value = "au"
    // this.userId='arcweb'+ new Date().getTime() + this.MathRand();
  },
  mounted() {
    // this.$intercom.boot({
    //   app_id: "uu6ddns6",
    //   user_id: this.userId,
    //   name: this.name,
    //   email: this.email
    // });
  },
  watch: {
    // email(email) {
    //   this.$intercom.update({ email });
    // }
  },
  methods:{ 
    changeLanguage(){
      let country = localStorage.getItem('country')
      let url;
      let host = window.location.host;

      if(this.value == country){
        return;
      }else if((this.value != country) && this.value == "au"){
        sessionStorage.setItem('doubleCheckID','0')
        if(host.indexOf('staging')>-1){
            let auhost = host.split('arcanite.id')
            url = auhost[0].concat('arcnet.com.au');
        } else{
            url = replaceUrl(host, 'au');
        }
        let path = window.location.protocol + '//' + url
        if(window.location.href.indexOf('http://localhost')>-1){
          var testurl = 'localhost:8080/'
          window.location.href = window.location.protocol + '//' + testurl
        }else{
          window.location.href = path
        }
        localStorage.setItem('country','au')
        
      }else if((this.value != country) && this.value == "id"){
        sessionStorage.setItem('doubleCheckID','1')
        let idhost
        if(host.indexOf('staging')>-1){
          idhost = host.split('arcnet.com.au')
          url = idhost[0].concat('arcanite.id')
        }else{
          url = replaceUrl(host, 'id');
        }
        let path = window.location.protocol + '//' + url
        // window.HubSpotConversations.widget.remove(); 
        if(window.location.href.indexOf('http://localhost')>-1){
          var testurl = 'localhost:8080/'
          window.location.href = window.location.protocol + '//' + testurl
        }else{
          window.location.href = path
        }
        localStorage.setItem('country','id')
      }
    },
    goRouter(path){
      this.$router.push(path)
    },
    // handleBookBtn() {
    //   this.$intercom.show();
    // },
    MathRand(){
      var Num="";
      for(var i=0;i<6;i++)
      {
      Num+=Math.floor(Math.random()*10);
      }
      return Num
    }, 
    handleClose(){
      // this.isShow=false
      this.$emit('changeIsShow')
    }
  },
 
}
</script>
<style lang="scss" scoped>
  .footer{
    position: relative;
    // overflow-x:hidden;
    .firstLine{
      background-color: #FFFFFF;
      width:100vw;
      display: inline-block;
      text-align: center;
        height: 563px;
      .footerTop {
        margin:auto;
        width: 1210px;
        // padding: 0 35px 0 35px;
        // margin: 125.9px 0 95px;
        // padding: 40.6px 648px 51.5px 608px;
        display: flex;
        padding-top: 80.1px;
        .left{
          // width: 934px;
          height: 100%;
          display: flex;
          .col{
            margin:0 10px 0 0;
            width: 276.7px;
            text-align: left;
            h1{
              // margin: 91px 10px 30px 247px;
              margin:10px;
              font-family: Poppins-Bold;
              font-size: 16px;
              // font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.38;
              letter-spacing: normal;
              text-align: left;
              color: #062440;
              display: inline-block;
              padding: 10px;
            }
            p{
              margin-left: 10px;
              flex-grow: 0;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              // gap: 10px;
              padding: 10px;
              font-family: Poppins;
              color: #67788C;;
              font-size: 14px;
              line-height: 1.43;
            }
            p:hover{
              cursor: pointer;
            }
          }
        }
        .right{
          h1{
            margin-top: 20px;
            flex-grow: 0;
            font-family: Poppins-Bold;
            font-size: 24px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #062440;;
          }
          .btn{
            display: flex;
            // margin-top: 30px;
            margin: 30px 0 60px 0;
            .btnA{
              width: 175px;
              height: 42px;
              flex-grow: 0;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              // gap: 10px;
              // padding: 10px 16px;
              border-radius: 5px;
              background-color: #1890ff;
              margin-right: 20px;
              img{
                // margin-top:10px;
                width: 24px;
                height: 24px;
              }
            }
            .IDbtnA{
              width: 212px;
            }
            .btnA:hover{
              background-color:#096dd9;
            }
            .btnA:active{
              background-color: #0757ae;
            }
            .btnB{
              width: 134px;
              height: 26px;
              flex-grow: 0;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              // gap: 10px;
              padding: 8px 16px;
              border-radius: 5px;
              border: solid 1px #fff;
            }
          }
          .icons{
            display: flex;
            margin-top: 20px;
            img{
              width: 24px;
              height: 24px;
              margin:10px 28px 10px 0px;
            }
          }
        }
        .text{
          // width: 112px;
          // height: 22px;
          flex-grow: 0;
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }
    }
    .secondLine{
      border-top: 1px solid #E5E6EB;
      background-color: #FFFFFF;
      width:100vw;
      height: 100%;
      display: inline-block;
      text-align: center;
      .footBottom{
        margin: auto;
        width: 1210px;
        // padding: 19.4px 630px 25.6px 608px;
        // background-color: #04192d;
        display: inline-flex;
        vertical-align: centre;
        .middle{
          padding:20.1px 0 21.1px 10px;
          display: flex;
          display: inline-flex;
          vertical-align: centre;
          .icon1{
            width: 158.89px;
            height: 49px;
          }
          p{
            // width: 130px;
            height: 20px;
            flex-grow: 0;
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #67788C;
            padding:10px;
            margin-top: 7px;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .arrow{
    width: 22px;
    height: 22px;
    margin-left: 10px;
  }
  p:hover{
    cursor: pointer;
  }


  .ImgSpace{
    height: 24px;
    width:24px;
    margin:0 !important;
  }
  
  

  .selectStyle{
    width: 62px;
    // min-height: 44px !important;
    
    // >>> input{
    //   border:none;
    // }
    // border:none !important;
  }

  .countryOptions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
    gap:8px;
  }
  .countryOptionsText{
    font-family: 'Poppins-bold';
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.012em;
    color: #314455;
  }

  .countryImg{
    height:24px;
    width:24px;
  }
  .countryImgOutSide{
    height:24px;
    width:24px;
    padding-top: 8px;
  }
</style>


<style lang="scss">

.selectStyle{
  width: 99px;
  .el-input__inner{
    border:none;
    font-family: 'Poppins-bold';
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.012em;
    color: #314455;
    padding-left:8px;
    padding-right:30px;
  }

  .el-input__icon{
    color: #314455 !important;
    font-weight:700;
  }

}
</style>
