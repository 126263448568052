export function toAddressString(result, with_country) {
  let countryObj = {'001':'Australia','002':'China'}
  // JSON.parse(localStorage.getItem('selectlist')).Country.forEach(ele => {
  //     countryObj[ele.countryid] = ele.countryname;
  // })
  let suburb = result.suburb ? result.suburb : '';
  let state = result.state ? result.state : '';
  let postcode = result.postcode ? result.postcode : '';
  let country = result.country ? countryObj[result.country] : '';
  if (result.country == '002') {
      // 中国
      let addressline = result.addressline ? result.addressline : '';
      let addressline2 = result.addressline2 ? result.addressline2 : '';
      return country + state + suburb + addressline + addressline2
  } else {
      let addressline = result.addressline ? result.addressline + ', ' : '';
      let addressline2 = result.addressline2 ? result.addressline2 + ', ' : '';
      if (with_country) {
          return addressline + addressline2 + suburb + ' ' + state + ' ' + postcode + ' ' + country;
      } else {
          return addressline + addressline2 + suburb + ' ' + state + ' ' + postcode;
      }
  }
}
export function toAddressString2(result2, with_country) {
  var result = {
      country: result2.projectcountry,
      postcode: result2.projectpostcode,
      state: result2.projectstate,
      suburb: result2.projectsuburb,
      addressline2: result2.projectaddressline2,
      addressline: result2.projectaddressline,
  }
  return toAddressString(result, with_country);
}
export function toSignNumber(num) {
  if (num > 0) {
      return '+' + num
  } else {
      return num
  }
}
// 基数转序数
export function getOrdinal(number) {
  var tail = null;
  if (number <= 0) {
      return "";
  } else if (1 == number) {
      return "1st";
  } else if (2 == number) {
      return "2nd";
  } else if (2 == number) {
      return "3rd";
  } else if (number >= 20) {
      var last = number % 10;
      if (1 == last) {
          tail = "st";
      } else if (2 == last) {
          tail = "nd";
      } else if (3 == last) {
          tail = "rd";
      } else {
          tail = "th";
      }
  } else {
      tail = "th";
  }
  return number + tail;
}

export function replaceUrl(host, newArea) {
    const domainExt = ['.com.au', '.au', '.id'];
    domainExt.forEach(ext => {
        if (host.endsWith(ext)) {
            host = host.replace(ext, "." + newArea);
        }
    })
    return host;
}
